<template>
  <div
    class="slide-in-container email"
    :class="{'active' : show === true}"
  >
    <div class="slide-in-bg" />
    <div class="slide-in-content">
      <div class="flexible">
        <div class="header justify-between">
          <div>
            <h1 class="text-xl mb-5">
              <span v-if="mode == 'edit'">Edit</span> <span v-else>View</span> email
            </h1>
            <div v-if="mode == 'edit'">
              <div
                v-if="email.templates.length > 0"
                class="select-container"
              >
                <select
                  v-model="email.selectEmailTemplate"
                  name="templates"
                  @change="updateEmailByTemplate"
                >
                  <option
                    value=""
                    selected="selected"
                  >
                    Select mail template
                  </option>
                  <option
                    v-for="(item, index) in email.templates"
                    :key="index"
                    :value="item.template.id"
                  >
                    {{ item.template.name }}
                  </option>
                </select>
              </div>
              <div v-else>
                <router-link
                  :to="`/templates/mails/create?project=${project.id}`"
                  class="text-green mt-3 inline-block underline"
                >
                  Create your first mail template
                </router-link>
              </div>
            </div>
          </div>
          <div>
            <div
              class="close"
              @click="cancel"
            />
          </div>
        </div>
        <div class="input-section mt-8">
          <div class="email-tag-input">
            <div class="inline-block">
              To:
            </div>
            <vue-tags-input
              v-show="mode == 'edit'"
              v-model="email.toEmail"
              placeholder="New email address"
              :tags="email.emailTags"
              :autocomplete-items="email.autocompleteEmailTagItems"
              :add-on-key="[188,13]"
              @tags-changed="updateEmailTags"
            />
            <div
              v-show="mode == 'view'"
              class="inline-block ml-2 py-3"
            >
              {{ email.toEmail }}
            </div>
          </div>
        </div>
        <div class="input-section">
          <div v-show="mode == 'edit'">
            <input
              v-model="email.fromEmailView"
              v-validate="{ required: true }"
              type="text"
              class="w-full input-modal"
              name="fromEmail"
              placeholder="From email"
              readonly="readonly"
            >
          </div>
          <div
            v-show="mode == 'view'"
            class="py-3"
          >
            {{ email.fromEmailView }}
          </div>
        </div>
        <div class="input-section">
          <div v-show="mode == 'edit'">
            <input
              v-model="email.subjectEmail"
              v-validate="{ required: true }"
              type="text"
              class="w-full input-modal"
              name="subjectEmail"
              placeholder="Subject"
            >
          </div>
          <div
            v-show="mode == 'view'"
            class="py-3"
          >
            {{ email.subjectEmail }}
          </div>
        </div>
        <div
          v-show="mode == 'edit'"
          class="h-full"
        >
          <!-- <v-editor
            class="h-full"
            :blank-email="email.isBlankEmail"
            :update-pending.sync="email.emailUpdatePending"
            :editor-content.sync="email.contentEmail"
          /> -->
          <v-ckeditor-token v-model="email.contentEmail" />
        </div>
        <div
          v-show="mode == 'view'"
          class="viewEmail flex-auto"
          v-html="email.contentEmail"
        />
        <div v-if="mode == 'edit'">
          <div
            v-if="!isSendingMail"
            class="flex justify-end pb-5"
          >
            <div>
              <v-button
                class="btn--secondary"
                @click="cancel"
              >
                <span class="px-2">Cancel</span>
              </v-button>
              <v-button
                class="btn--secondary ml-4 "
                @click="saveAsDraft"
              >
                <span class="text-green underline px-2">Save</span>
              </v-button>
            </div>
            <div class="px-4">
              <v-button
                class="green-btn"
                @click="send"
              >
                <span class="px-2">Send</span>
              </v-button>
            </div>
          </div>
          <div
            v-else
            class="text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="40"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  props: {
    project:{
      type: Object,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    show: {
      type: Boolean,
      default: false
    },
    templates:{
      type: Array,
      default: null,
    },
    email:{
      type: Object,
      default: null
    }
  },
  data(){
    return {
      loading: false,
      isSendingMail: false,
      user: auth.user(),
      templateMail: null,
      selectedLocale: '',
    }
  },
  computed: {
    languages() {
      return !!this.templateMail
        ? Object.keys(this.templateMail.subject)
        : [];
    },

    defaultLanguage() {
      return this.project
        ? Object.values(this.user.companySettings.locales).find(locale => locale.code === this.project.locale)
        : ''
    }
  },
  watch: {
    selectedLocale() {
      this.email.subjectEmail = this.templateMail ? this.templateMail.subject[this.selectedLocale] : '';
      this.email.contentEmail = this.templateMail ? this.templateMail.content[this.selectedLocale] : '';
    },
    project: {
      deep: true,
      handler() {
        this.selectedLocale = this.defaultLanguage ? this.defaultLanguage.code : '';
      }
    }
  },
  mounted() {
    this.selectedLocale = this.defaultLanguage ? this.defaultLanguage.code : '';
  },
  methods: {
    async updateEmailByTemplate(){

      if(this.email.selectEmailTemplate == '') return
      this.email.emailUpdatePending = false

      try {
        const { data } = await this.$api.get('templateMail').detail(this.user.company.id, this.email.selectEmailTemplate)

        if (! data.mail.subject[this.selectedLocale] || ! data.mail.content[this.selectedLocale]) {
          this.email.subjectEmail = '';
          this.email.contentEmail = '';

          const shouldRedirect = await this.$alert.confirm({
            title: 'This template is not translated in this language',
            text: '',
            buttons: ['Cancel', 'Add translation']
          });

          if (shouldRedirect) {
            this.$router.push(`/templates/mails/${this.email.selectEmailTemplate}`)
          }
        } else {
          this.templateMail = data.mail;

          this.email.subjectEmail = data.mail.subject[this.selectedLocale] || '';
          this.email.contentEmail = data.mail.content[this.selectedLocale] || '';

          const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature
          this.email.contentEmail += signature

          this.email.emailUpdatePending = true
        }
      } catch(e){
        logException(e)
      }
    },
    updateEmailTags(newTags){
      this.email.autocompleteEmailTagItems = []
      this.email.emailTags = newTags
    },
    cancel(){
      this.$emit('close')
    },
    async initEmailTagItems(){
      if (this.email.toEmail.length === 0) return

      this.email.autocompleteTagItems.push({
        text: this.email.toEmail
      })
    },
    async saveAsDraft(){
      const postData = {
        itemId: this.email.emailId,
        type: this.email.emailType,
        to: this.email.emailTags,
        from: this.email.fromEmail,
        subject: this.email.subjectEmail,
        content: this.email.contentEmail,
      }

      try {
        const { data } = await this.$api.get('mail').saveAsDraft(postData)

        this.$toasted.global.general_success({
          message : 'Email saved as draft.'
        })
        this.$emit('saved')
      } catch(e){
        this.$toasted.global.general_error({
          message : 'Subject field or email content is empty.'
        })
        logException(e)
      }
    },
    async send(){
      this.$toasted.clear()
      const postData = {
        itemId: this.email.emailId,
        type: this.email.emailType,
        to: this.email.emailTags,
        from: this.email.fromEmail,
        subject: this.email.subjectEmail,
        content: this.email.contentEmail,
        senderName: this.user.companySettings.senderName
      }

      if(this.email.subjectEmail == ''){
        this.$toasted.global.general_error({
          message : 'Subject field is missing.'
        })
        return false
      }

      if(this.email.isBlankEmail){
        if(this.email.contentEmail.includes('@documentLink')){
          this.$toasted.global.general_error({
            message : '@documentLink token can only be used when sending out a document.'
          })
          return false
        }
      }

      try {
        this.isSendingMail = true
        const { data } = await this.$api.get('mail').send(postData)
        let emailType = this.email.emailType
        const typeName = emailType.charAt(0).toUpperCase() + emailType.slice(1)

        if(!this.email.isBlankEmail){
          this.$toasted.global.general_success({
            message : `${typeName} has been sent!`
          })
        } else {
          this.$toasted.global.general_success({
            message : 'Email has been sent!'
          })
        }

        this.isSendingMail = false
        this.$emit('saved')

      } catch(e){
        this.isSendingMail = false
        this.$toasted.global.api_error(e.response.data)
      }
    },
  }
}
</script>

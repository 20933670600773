<template>
  <div id="outer-wrapper">
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />

    <questionnaire-email-slide-in
      v-if="project"
      :project.sync="project"
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <v-inner-header
      type="questionnaire"
      mode="edit"
      :item="questionnaire"
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :user-might-update.sync="userMightUpdate"
      :redirect="`/projects/${$route.params.id}`"
      @click="alertExit"
      @share="copyShareLink"
      @preview="saveAndPreview"
      @deleteItem="deleteItem"
      @markAsSent="markAsSent"
      @markAsUnsent="markAsUnsent"
    />

    <div class="pt-16">
      <div class="flex">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          class="w-full px-2 mt-16"
        >
          <div class="max-w-md mx-auto px-4">
            <div class="bg-white shadow rounded px-8 py-8">
              <div class="flex items-center justify-between mb-2">
                <div class="w-full">
                  <h1 class="text-xl">
                    Questionnaire
                  </h1>
                </div>
              </div>
              <p class="text-sm mb-3 text-grey-dark">
                Give this questionnaire a name.
              </p>
              <input
                v-model="formSetting.name"
                v-validate="{ required: true }"
                type="text"
                class="w-full"
                name="name"
                placeholder="Name of questionnaire"
              >
              <span class="error inline-block mt-2">{{ errors.first('name') }}</span>


            </div>
            <div id="lead-form">
              <div class="lead-form-edit">
                <div class="flex heading text-center">
                  <div
                    class="w-1/2 tab-button"
                    :class="{'active' : view == 'create'}"
                    @click="changeView('create')"
                  >
                    Create
                  </div>
                  <div
                    class="w-1/2 tab-button"
                    :class="{'active' : view == 'preview'}"
                    @click="changeView('preview')"
                  >
                    Preview
                  </div>
                </div>
                <div
                  v-show="view == 'create'"
                  class="py-5"
                >
                  <h1 class="text-xl mb-2 px-8">
                    Create Questionnaire
                  </h1>
                  <div class="px-8 mt-5 mb-3">
                    <v-checkbox
                      id="showLogo"
                      v-model="formSetting.showLogo"
                      :checked="formSetting.showLogo ? true : false"
                      label="Show my company logo on top of the questionnaire"
                    />
                  </div>
                  <div class="px-8 mt-5 mb-3">
                    <v-checkbox
                      id="isRedirectOn"
                      v-model="formSetting.isRedirectOn"
                      :checked="formSetting.isRedirectOn ? true : false"
                      label="Redirect to another page after submitting the form"
                    />
                  </div>
                  <div
                    v-show="formSetting.isRedirectOn"
                    class="px-8"
                  >
                    <input
                      v-model="formSetting.redirectLink"
                      type="text"
                      placeholder="Enter a website URL"
                    >
                  </div>

                  <div
                    class="pb-4 mt-5 content px-8 text-sm"
                  >
                    <p class="text-sm mt-5 mb-3 ">
                      Give it a title which your client will see.
                    </p>
                    <input
                      v-model="formSetting.title"
                      v-validate="{ required: true }"
                      type="text"
                      class="w-full"
                      name="title"
                      placeholder="Title of the questionnaire"
                    >
                    <span class="error inline-block mt-2">{{ errors.first('title') }}</span>
                  </div>
                  <div
                    class="pb-4 content px-8 text-sm"
                  >
                    Welcome message (optional)
                    <textarea-autosize
                      v-model="formSetting.welcomeMessage"
                      placeholder="Show a welcome message at the top of the form."
                      class="block mt-3 w-full"
                      :min-height="85"
                      :max-height="100"
                    />
                  </div>

                  <div
                    v-show="!formSetting.isRedirectOn"
                    class="pb-8 content px-8 text-sm"
                  >
                    Thank you message
                    <textarea-autosize
                      v-model="formSetting.successMessage"
                      placeholder="Show a thank you message when a potential client filled in your form."
                      class="block mt-3 w-full"
                      :min-height="85"
                      :max-height="100"
                    />
                    <span
                      v-show="successMessageError"
                      class="error inline-block mt-2"
                    >Please enter a message to show when a client has submitted the form.</span>
                  </div>
                </div>

                <div
                  v-show="view == 'preview'"
                  class="py-5 content text-sm"
                >
                  <div
                    class="px-3 mx-8 rounded py-6"
                  >
                    <div
                      v-if="listofFields.length < 1"
                      class="text-center my-10"
                    >
                      Add some fields to see a preview of your questionnaire.
                    </div>

                    <div
                      v-if="listofFields.length > 0 && logo.path && formSetting.showLogo"
                      class="logo text-center"
                    >
                      <img
                        :src="logo.path"
                        width="100%"
                        :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                      >
                    </div>

                    <div
                      v-if="listofFields.length"
                      class="my-10 px-8"
                    >
                      <h1
                        v-if="formSetting.title != ''"
                        class="mb-3"
                      >
                        {{ formSetting.title }}
                      </h1>
                      <p v-if="formSetting.welcomeMessage != ''">
                        {{ formSetting.welcomeMessage }}
                      </p>
                    </div>

                    <div
                      v-for="item in listofFields"
                      :key="item.id"
                      class="fields-container "
                    >
                      <div class="fields-preview mb-2 px-8 active">
                        <div class="input">
                          <div
                            v-if="item.type != 'staticText'"
                            class="flex w-full"
                          >
                            <div class="w-1/2">
                              <div
                                class="mb-2"
                              >
                                {{ item.label }}
                              </div>
                            </div>
                            <div class="w-1/2 text-right">
                              <span
                                v-if="item.is_required"
                                class="text-grey-semi-light"
                              >Required</span>
                            </div>
                          </div>
                          <div v-if="item.type == 'text'">
                            <input
                              type="text"
                              class="lead"
                            >
                          </div>
                          <div v-if="item.type == 'datepicker'">
                            <v-date-picker
                              class="datepicker"
                              v-model="fakeDate"
                              :popover="{ visibility: 'click' }"
                              :masks="{L: 'WWW, MMM D, YYYY'}"
                            >
                              <template v-slot="{ inputValue, inputEvents }">
                                <input
                                  type="text"
                                  :value="inputValue"
                                  v-on="inputEvents"
                                />
                              </template>
                            </v-date-picker>
                          </div>
                          <div v-if="item.type == 'textarea'">
                            <textarea
                              class="lead"
                            />
                          </div>
                          <div
                            v-if="item.type == 'staticText'"
                            v-html="item.label"
                          />

                          <div v-if="item.type == 'dropdown'">
                            <div
                              class="select-container tall w-full"
                            >
                              <select>
                                <option
                                  v-for="option in item.options"
                                  :value="option"
                                >
                                  {{ option }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div v-if="item.type == 'checkbox'">
                            <div
                              v-for="option in item.options"
                              class="mb-2"
                              :value="option"
                            >
                              <v-checkbox
                                :name="item.label"
                                :label="option"
                              />
                            </div>
                          </div>
                          <div
                            v-if="item.is_required"
                            class="error mt-1"
                          >
                            The {{ item.label | formatToLowerCase }} field is required.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="listofFields.length > 0"
                      class="text-center"
                    >
                      <input
                        type="submit"
                        name="submit"
                        value="Submit"
                        class="btn green-btn"
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  v-show="view == 'create'"
                  class="content text-sm"
                >
                  <!-- Start Custom -->
                  <div v-show="listofFields.length > 0">
                    <!-- Draggable -->
                    <draggable
                      :list="listofFields"
                      handle=".drag"
                      drag-class="drag-helper"
                      animation="200"
                    >
                      <transition-group
                        type="transition"
                        name="flip-list"
                      >
                        <div
                          v-for="(item, index) in listofFields"
                          :key="index"
                        >
                          <div
                            class="draggable-item bg-white rounded"
                            @click="toggleActive(item)"
                          >
                            <div class="fields-container">
                              <div
                                v-if="item.isActive"
                                class="fields-edit pt-3 pb-5 px-8 mb-5 "
                              >
                                <div class="flex flex-wrap border-grey-lighter border-b pb-3">
                                  <div class="drag w-full">
                                    <div class="icon pt-1 pb-3 text-center">
                                      <img src="@/assets/img/icons/drag.svg">
                                    </div>
                                  </div>
                                  <div class="w-full">
                                    <div class="flex flex-wrap">
                                      <div
                                        v-if="item.type != 'staticText'"
                                        class="w-full md:w-1/2"
                                      >
                                        <div
                                          class="input-group mb-2"
                                        >
                                          <label class="inline-block w-24"> Field label</label>
                                          <input
                                            v-model="item.label"
                                            type="text"
                                            class="w-full max-w-xs mb"
                                            placeholder="Label (ex. Your email address)"
                                          >
                                        </div>
                                      </div>
                                      <div
                                        v-else
                                        class="w-full"
                                      >
<!--                                      <textarea-->
<!--                                        v-model="item.label"-->
<!--                                        placeholder="Static text block"-->
<!--                                        maxlength="511"-->
<!--                                      />-->
                                        <v-ckeditor v-model="item.label" />
                                        <div class="w-full text-right my-3">
                                          <span
                                            class="field-type-label uppercase"
                                          >Static text</span>
                                        </div>
                                      </div>
                                      <div class="w-full md:w-1/2 text-right mb-3 md:mb-0">
                                        <span
                                          v-if="item.type == 'text'"
                                          class="field-type-label uppercase"
                                        >Short question</span>
                                        <span
                                          v-if="item.type == 'textarea'"
                                          class="field-type-label uppercase"
                                        >Long question</span>
                                        <span
                                          v-if="item.type == 'datepicker'"
                                          class="field-type-label uppercase"
                                        >Date</span>
                                        <span
                                          v-if="item.type == 'dropdown'"
                                          class="field-type-label uppercase"
                                        >Single choice</span>
                                        <span
                                          v-if="item.type == 'checkbox'"
                                          class="field-type-label uppercase"
                                        >Multiple choice</span>
                                      </div>
                                    </div>
                                    <div
                                      v-if="item.type == 'checkbox' || item.type == 'dropdown'"
                                      class="input-group mb-2"
                                    >
                                      <label class="inline-block w-24">Items</label>

                                      <div v-for="(option, optionIndex) in item.options">
                                        <div class="flex items-center">
                                          <div class="mb-2 w-full md:w-4/12">
                                            <input
                                              v-model="item.options[optionIndex]"
                                              type="text"
                                              class="w-full max-w-xs mb"
                                              placeholder="Options"
                                            >
                                          </div>
                                          <div class="ml-2">
                                            <img
                                              src="@/assets/img/icons/ico-delete-green.svg"
                                              width="15"
                                              class="mb-1"
                                              @click="removeDropdownOption(index, optionIndex)"
                                            >
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="text-green underline mt-1"
                                        @click="addDropdownOption(index)"
                                      >
                                        + Add option
                                      </div>
                                    </div>
                                    <div
                                      v-if="!item.is_mapping_fix && item.type != 'checkbox' && item.type != 'staticText'"
                                      class="input-group mb-2"
                                    >
                                      <label class="inline-block">
                                        Save data to <v-help-tooltip
                                          :options="tooltipContent"
                                        />
                                      </label>
                                      <div
                                        v-if="!item.is_mapping_fix"
                                        class="select-container tall "
                                      >
                                        <select
                                          v-model="item.mapping"
                                          class="w-32"
                                          @change="changeMapping(item.id)"
                                        >
                                          <option value="nothing">
                                            Nothing
                                          </option>
                                          <option
                                            v-for="map in mapping_options"
                                            v-if="(map.value == 'date' && item.type =='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                            :key="map.id"
                                            :value="map.value"
                                          >
                                            {{ map.label }}
                                          </option>
                                          <option
                                            v-for="map in mapping_options"
                                            v-if="(map.value != 'date' && item.type !='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                            :key="map.id"
                                            :value="map.value"
                                          >
                                            {{ map.label }}
                                          </option>
                                        </select>
                                      </div>
                                      <div v-else>
                                        <div class="select-container tall">
                                          <select
                                            v-model="item.mapping"
                                            class="capitalize w-32"
                                          >
                                            <option :value="item.mapping">
                                              {{ item.mapping }}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="flex flex-row-reverse text-right">
                                  <div class="pt-3">
                                    <a
                                      v-if="!item.is_mapping_fix"
                                      class="underline blue mr-2 pr-2 pt-3 pb-2 border-r border-grey-lighter"
                                      @click="removeAdditionalFields(index)"
                                    >Delete</a>
                                    <v-checkbox
                                      v-if="!item.is_mapping_fix"
                                      :id="`listofFields${index}`"
                                      v-model="item.is_required"
                                      :active="(item.is_mapping_fix) ? 'disabled' : ''"
                                      label="Required"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                class="fields-preview mb-5 py-5 px-8 active"
                              >
                                <div class="input">
                                  <div v-if="item.type != 'staticText'">
                                    <div class="flex w-full">
                                      <div class="w-1/2">
                                        <div class="mb-3">
                                          {{ item.label }}
                                        </div>
                                      </div>
                                      <div class="w-1/2 text-right">
                                        <span
                                          v-if="item.is_required"
                                          class="text-grey-semi-light"
                                        >Required</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="item.type == 'text'">
                                    <input
                                      type="text"
                                      class="lead"
                                      disabled="disabled"
                                    >
                                  </div>
                                  <div v-if="item.type == 'datepicker'">
                                    <input
                                      type="text"
                                      class="lead"
                                      disabled="disabled"
                                    >
                                  </div>
                                  <div v-if="item.type == 'textarea'">
                                    <textarea
                                      class="lead"
                                      disabled="disabled"
                                    />
                                  </div>
                                  <div
                                    v-if="item.type == 'staticText'"
                                    class="leading-normal"
                                    v-html="item.label"
                                  />
                                  <div
                                    v-if="item.type == 'checkbox'"
                                    class="leading-normal"
                                  >
                                    <div
                                      v-for="option in item.options"
                                      class="mb-2"
                                      :value="option"
                                    >
                                      <v-checkbox
                                        :name="item.label"
                                        :label="option"
                                      />
                                    </div>
                                  </div>
                                  <div v-if="item.type == 'dropdown'">
                                    <div
                                      class="select-container tall w-full"
                                    >
                                      <select disabled="disabled">
                                        <option
                                          v-for="option in item.options"
                                          :value="option"
                                        >
                                          {{ option }}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition-group>
                    </draggable>
                    <!-- End Draggable -->
                  </div>
                  <div class="mt-8 mb-5 bg-white rounded px-8 py-6">
                    <h1 class="text-lg mb-6">
                      Choose a question type
                    </h1>
                    <div class="flex flex-wrap items-center">
                      <div class="w-full">
                        <a
                          class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('text')"
                        >
                          + Short question
                        </a>
                        <a
                          class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('textarea')"
                        >
                          + Long question
                        </a>
                        <a
                          class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('dropdown')"
                        >
                          + Single choice
                        </a>
                      </div>
                      <div class="w-full">
                        <a
                          class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('staticText')"
                        >
                          + Static text
                        </a>
                        <a
                          class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('checkbox')"
                        >
                          + Multiple Choice
                        </a>
                        <a
                        class="inline-block green-btn mr-3 mb-3"
                          @click="addAdditionalFields('datepicker')"
                        >
                          + Date
                        </a>
                      </div>
                    </div>
                  </div>

                  <!-- End custom item -->
                </div>
                <!-- End create view -->
              </div>
            </div>
          </div>
          <div class="max-w-md mx-auto px-4">
            <div
              class="flex flex-wrap mt-5 mb-10  text-right"
            >
              <div
                v-if="isSaving"
                class="w-full"
              >
                <img
                  src="@/assets/img/icons/loader.svg"
                  width="40"
                >
              </div>
              <div
                v-else
                class="w-full"
              >
                <div class="inline-block mb-3">
                  <a
                    v-if="formState == 'draft'"
                    class="ml-3 green underline cursor-pointer"
                    @click="save(true)"
                  >Save and edit later</a>
                  <a
                    class="ml-3 cursor-pointer green underline"
                    @click="saveAndPreview"
                  >Save and view</a>
                </div>
                <v-button
                  v-if="formState == 'draft'"
                  class="ml-3"
                  @click="showEmailSlideIn"
                >
                  Compose email
                </v-button>
                <v-button
                  v-else
                  class="ml-3"
                  @click="save(true)"
                >
                  Save
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import auth from '@/auth'
import QuestionnaireEmailSlideIn from './Extension/QuestionnaireEmailSlideIn'
import { logException } from '@/helpers'
import draggable from 'vuedraggable'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  components: {
    draggable,
    QuestionnaireEmailSlideIn,
  },
  data(){
    return {
      metaTitle: 'Loading',
      logo: {path:'', width:0, height:0 },
      isSaving: false,
      isSaved: false,
      project: null,
      contact: null,
      isSendingMail: false,
      isPreview: false,
      user: auth.user(),
      loadTemplateId: null,
      timezone: '',
      emailEditMode: 'edit',
      showEmailSlide: false,
      email: {
        emailType: 'questionnaire',
        emailId: null,
        isBlankEmail: false,
        autocompleteEmailTagItems: [],
        selectEmailTemplate: '',
        emailTags: [],
        templates: [],
        fromEmailView: '',
        toEmail: '',
        fromEmail: '',
        subjectEmail:'',
        contentEmail: '',
        emailUpdatePending: false,
      },
      view: 'create',
      questionnaire: {
        state: 'draft',
      },
      formId: null,
      formState: 'draft',
      fakeDate: null,
      loading: false,
      mapping_options:[
      {
        label: 'Name',
        value: 'name',
        assignedTo: null,
      },
      {
        label: 'Email',
        value: 'email',
        assignedTo: null,
      },{
        label: 'Date',
        value: 'date',
        assignedTo: null,
      },{
        label: 'Location',
        value: 'location',
        assignedTo: null,
      },{
        label: 'Company',
        value: 'company',
        assignedTo: null,
      },{
        label: 'Phone',
        value: 'phone',
        assignedTo: null,
      },{
        label: 'Address',
        value: 'address',
        assignedTo: null,
      },{
        label: 'City',
        value: 'city',
        assignedTo: null,
      },{
        label: 'Postal code',
        value: 'postal_code',
        assignedTo: null,
      }
      ,{
        label: 'VAT',
        value: 'vat',
        assignedTo: null,
      }],
      formSetting: {
        token: '',
        name: '',
        title: '',
        showLogo: true,
        welcomeMessage: '',
        successMessage: '',
        isRedirectOn: false,
        redirectLink: '',
      },
      listofFields: [],
      listofForms: {},
      success: false,
      embedLink: null,
      successMessageError: false,
      tooltipContent: {
        content: `
          Store the input of this field to your contact sheet to the selected field. When selecting nothing you will still see it in your email and inside the project.
        `
      },
      userMightUpdate: false,
    }
  },
  computed: {
  },
  async mounted(){

    let newItem = true
    this.loading = true

    await this.initData()
    if(this.$route.params.questionnaireId){
      newItem = false
      await this.getQuestionnaire(this.$route.params.questionnaireId)
      this.metaTitle = `Edit Questionnaire: ${this.formSetting.name}`
    }

    if (this.$route.query.load && this.loadTemplateId) {
      newItem = false
      await this.getQuestionnaire(this.loadTemplateId, 'template')
      this.metaTitle = 'New Questionnaire'

      // Remove 'load' query parameter from the URL
      await this.$router.replace({ query: null })
    }

    this.loading = false
    await this.loadMailTemplates()

    if(!this.loading && newItem){
      this.metaTitle = 'New Questionnaire'
    }

    this.initDetectChanges()
  },
  methods: {
    async alertExit(){
      if(this.userMightUpdate){
        let link = `/projects/${this.$route.params.id}`

        const canDelete = await this.$alert.confirm({
          title: 'Are you sure?',
          text: 'You might have unsaved changes, remember to save before closing.',
        })

        if (canDelete) {
          this.$router.push(link)
        }
      }
    },
    initDetectChanges(){
      let elements = document.getElementsByTagName('input')
      let textAreaElements = document.getElementsByTagName('textarea')
      let that = this
      for(let i=0;i<elements.length;i++) {
        if(!(/submit/).test(elements[i].getAttribute('type'))) {
          elements[i].onchange = function(){that.detect(this)}
        }
      }
      for(let i=0;i<textAreaElements.length;i++) {
        if(!(/submit/).test(textAreaElements[i].getAttribute('type'))) {
          textAreaElements[i].onchange = function(){that.detect(this)}
        }
      }
    },
    detect(e){
      this.userMightUpdate = true
    },
    changeView(view){
      this.view = view
    },
    removeDropdownOption(index, optionIndex){
      this.listofFields[index].options.splice(optionIndex, 1)
    },
    addDropdownOption(index){
      this.listofFields[index].options.push('')
    },
    changeMapping(targetId){
      let that = this
      this.listofFields.forEach(function(item) {
        that.mapping_options.forEach(function(map) {
          if(item.mapping == map.value){
            map.assignedTo = item.id
          }
        })
      })
    },
    addAdditionalFields(type) {
      this.listofFields.forEach(function(item) {
        item.isActive = false
      })
      this.listofFields.push({
          id: new Date().getTime(),
          isActive: true,
          label: '',
          type: type,
          mapping: 'nothing',
          options: [],
          is_mapping_fix: false,
          is_required: false,
      })

      this.userMightUpdate = true
    },
    removeAdditionalFields(index){
      let field = this.listofFields[index]
      this.mapping_options.forEach(map => {
        if(map.assignedTo == field.id){
          map.assignedTo = null
        }
      })
      this.listofFields.splice(index, 1)
      this.userMightUpdate = true
    },
    toggleActive(target){
      this.listofFields.forEach(function(item) {
        item.isActive = false
        if(item.id == target.id){
          item.isActive = true
        }
      })
    },
    saveAndPreview(){
      this.isPreview = true
      this.save()
    },
    save(saveAndEditLater = false) {

      let that = this
      let error = false

      this.$toasted.clear()

      if(this.formSetting.name < 3){
        this.$toasted.global.general_error({
          message : 'Please add a name to the questionnaire.'
        })
        return false
      }

      if(this.formSetting.title < 3){
        this.$toasted.global.general_error({
          message : 'Please add a title to the questionnaire.'
        })
        return false
      }

      this.successMessageError = false

      if(this.formSetting.isRedirectOn){
        let url = this.formSetting.redirectLink
        const pattern = new RegExp('^https?:\\/\\/' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        if (!pattern.test(url)) {
            this.$toasted.global.general_error({
              message : 'Please enter a valid URL with http:// or https://'
            })
            return false
        }

      } else if(this.formSetting.successMessage == null || this.formSetting.successMessage.length < 3){
        this.$toasted.global.general_error({
          message : 'Thank you message cannot be empty.'
        })
        this.successMessageError = true
        error = true
        return false
      }

      if(this.listofFields.length < 1){
        this.$toasted.global.general_error({
          message : 'Please add at least one field.'
        })
        return false
      }

      this.listofFields.forEach(function(item) {
        let message = 'Field label cannot be empty.'
        if(item.label == ''){
          if(item.type == 'staticText'){
            message = 'Static message cannot be empty.'
          }
          that.$toasted.global.general_error({
            message : message
          })
          error = true
        }
      })

      if (error) {
        return false
      }

      this.doSave(saveAndEditLater)
    },
    async doSave(saveAndEditLater = false) {

      this.isSaving = true

        try {
          const postData = {
            companyId: this.user.company.id,
            projectId: this.project.id,
            formId: this.formId,
            name: this.formSetting.name,
            title: this.formSetting.title,
            showLogo: this.formSetting.showLogo,
            isRedirectOn: this.formSetting.isRedirectOn,
            redirectLink: this.formSetting.redirectLink,
            welcomeMessage: this.formSetting.welcomeMessage,
            successMessage: this.formSetting.successMessage,
            fields: this.listofFields,
          }

          const { data } = await this.$api.get('questionnaire').save(postData)
          this.formId = data.questionnaire.id
          this.formState = data.questionnaire.state
          this.formSetting.token = data.questionnaire.token

          if(saveAndEditLater){
            this.$router.push(`/projects/${this.$route.params.id}`)
            return false
          }

          if (this.$route.query.load && this.loadTemplateId && this.formId && !this.isPreview && !this.showEmailSlide) {
            window.open(`/projects/${this.$route.params.id}/questionnaire/${this.formId}?send=1`, '_self')
            return false
          }

          if(!this.email.contentEmail){
            this.generateEmailContent()
          }

          if(this.isPreview){
            let popUp = this.$router.push(`/questionnaire/preview/${this.formId}`)
            this.isPreview = false
          } else {
            if(this.isPaymentFieldEmpty){
              this.showBankSlide = true
             } else {
              this.email.emailId = this.formId
              this.showEmailSlide = true
            }
          }

          this.userMightUpdate = false
          this.isSaved = true
          this.isSaving = false

          this.$toasted.global.general_success({
            message : 'Questionnaire saved.'
          })

        } catch(e){
          this.isSaving = false
          logException(e)
        }
    },
    async getQuestionnaire(id, type = null){
      this.loading = true

      try {

        let result = {}
        if(type == 'template'){
          result = await this.$api.get('templateQuestionnaire').detail(this.user.company.id, id)
        } else {
          result  = await this.$api.get('questionnaire').detail(id, 'edit')
          this.formState = result.data.questionnaire.state
        }

        let data = result.data
        this.questionnaire = data.questionnaire
        if(type != 'template') {
          this.formId = data.questionnaire.id
        }
        this.formSetting.token = data.questionnaire.token
        this.formSetting.name = data.questionnaire.name
        this.formSetting.title = data.questionnaire.title
        this.formSetting.welcomeMessage = data.questionnaire.welcome_message
        this.formSetting.successMessage = data.questionnaire.success_message
        this.formSetting.showLogo = data.questionnaire.show_logo
        this.formSetting.isRedirectOn = data.questionnaire.is_redirect_on
        this.formSetting.redirectLink = data.questionnaire.redirect_link

        this.listofFields = []

        data.questionnaire.fields.forEach(field => {
          let options = []
          if(field.options){
            options = field.options
          }
          this.listofFields.push({
            id: field.id,
            isActive: false,
            label: field.label,
            type: field.type,
            mapping: field.mapping,
            options: options,
            is_mapping_fix: field.is_mapping_fix,
            is_required: field.is_required,
          })
        })
        this.loading = false

      } catch(e){
        logException(e)
      }

    },
    redirectBack(){
      this.$router.push(`/projects/${this.$route.params.id}`)
    },
    showEmailSlideIn(){
      this.isPreview = false
      this.save()
    },
    async savedEmailSlideIn(){
      this.showEmailSlide = false
      this.isSendingMail = false
      this.$router.push(`/projects/${this.$route.params.id}`)
    },
    async hideEmailSlideIn(){
      this.showEmailSlide = false
    },
    async loadMailTemplates(){
      try {
        const { data } = await this.$api.get('templateMail').list(this.user.company.id)
        this.email.templates = []

        data.mails.forEach(template => {
          this.email.templates.push({template})
        })
      } catch(e){
        logException(e)
      }
    },
    async loadDraft(){

      this.email.selectEmailTemplate = ''

      try {
        const { data } = await this.$api.get('mail').loadDraft(`${this.formId}`, 'questionnaire')

        if(data.result != 'Empty'){

          this.email.emailTags = []
          data.toEmails.forEach(email => {
            this.email.emailTags.push({
              text: email,
            })
          })

          this.email.fromEmail = data.from
          this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
          this.email.subjectEmail = data.subject
          this.email.contentEmail = data.content
          this.email.emailUpdatePending = true
        }
      } catch(e){
        logException(e)
      }
    },
    updateEmailTags(newTags){
      this.email.autocompleteEmailTagItems = []
      this.email.emailTags = newTags
    },
    generateEmailContent(){
      this.email.emailTags = []
      this.email.emailTags.push({
        text: this.contact.email,
      })

      const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature
      const link = `${config.frontBaseUrl}/view/${this.$route.params.id}/questionnaire/${this.formSetting.token}`

      this.email.contentEmail = `<p>Hi ${this.contact.first_name}, </p><p></p><p>I hereby send you my questionnaire! Please contact me if you have any questions or if things are not clear. I’m glad to help you further. <p><p></p><p>Link to questionnaire: <a href="@documentLink">@documentLink</a> </p><p>${signature}</p>`
      this.email.emailUpdatePending = true
    },
    async initData(){

      const templateId = this.$store.getters['template/loadTemplateId']
      this.loadTemplateId = templateId

      // Load settings
      this.email.fromEmail = this.user.companySettings.senderEmail
      this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`

      this.logo.path = this.user.companySettings.logo
      this.refreshLogo()

      try {
        const { data } = await this.$api.get('projects').load(this.$route.params.id)

        this.project = data.project
        this.contact = data.project.contact

      } catch(e){
        //this.redirectBack()
        logException(e)
      }

    },
    refreshLogo(){
      let that = this
      let image = new Image()
      image.src = this.logo.path
      image.onload = function () {
        that.logo.width = this.width
        that.logo.height = this.height
      }
    },
    async copyShareLink(){
      if(this.formState == 'draft'){
        const can = await this.$alert.confirm({
          title: 'Questionnaire has not been sent out',
          text: 'In order to copy a share link, we will mark this questionnaire as sent.'
        })

        if (can) {
          try {
            await this.$api.get('questionnaire').updateState(this.formId, 'sent')
              const link = `${config.frontBaseUrl}/view/${this.questionnaire.project.id}/questionnaire/${this.questionnaire.token}`
              let dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = link
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)

              this.$toasted.global.general_success({
                message : 'Share link has been copied to your clipboard.'
              })

              setTimeout(() => {
                window.location.reload()
              }, 1000)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }
        }
      } else {
        const link = `${config.frontBaseUrl}/view/${this.questionnaire.project.id}/questionnaire/${this.questionnaire.token}`
        let dummy = document.createElement('textarea')
        document.body.appendChild(dummy)
        dummy.value = link
        dummy.select()
        document.execCommand('copy')
        document.body.removeChild(dummy)

        this.$toasted.global.general_success({
          message : 'Share link has been copied to your clipboard.'
        })
      }
    },
    async deleteItem(){
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this questionnaire?',
        text: 'If you delete this, sent item will no longer accessible by the client.',
      })

      if (canDelete) {
        try {
          try {
            const postData = {
              formId: this.formId,
            }
            await this.$api.get('questionnaire').delete(postData)
            this.$router.push(`/projects/${this.$route.params.id}`)

          } catch(e){
            this.$toasted.global.api_error(e)
            logException(e)
          }

        } catch(e){
          logException(e)
        }
      }
    },
    async markAsSent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this questionnaire as sent?',
      })

      if (can) {
        try {
          await this.$api.get('questionnaire').updateState(this.formId, 'sent')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
    async markAsUnsent(){

      const can = await this.$alert.confirm({
        title: 'Are you sure you want to mark this questionnaire as draft?',
      })

      if (can) {
        try {
          await this.$api.get('questionnaire').updateState(this.formId, 'draft')
          setTimeout(() => {
            window.location.reload()
          }, 500)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      }
    },
  },
}
</script>
